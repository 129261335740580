.App-header {
  background-color: black;
  min-height: 10vh;

  color: #faf4ea;

  /* border-bottom: 1px solid white; */
}
.header-main {
  color: #e71d36;
}

.footer {
  background-color: black;
  color: #faf4ea;
  min-height: 5rem;
  font-weight: 500;
}
.fit {
  width: 100%;
}
main {
  background-color: #edf2f4;
  /* background-image: url('./Images/VicotriaMap.svg'); */
}

@font-face {
  font-family: 'Cramps';
  src: local('CrampsRegular'), url('./Fonts/FredokaOne-Regular.ttf');
}

@font-face {
  font-family: 'FredokaOne';
  src: local('FredokaOne'), url('./Fonts/FredokaOne-Regular.ttf');
}
@font-face {
  font-family: 'Fredoka';
  src: local('Fredoka'), url('./Fonts/Fredoka.ttf');
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
